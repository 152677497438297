<template>
  <div id="announcement-wrapper">
    <div class="banner">
      <div class="bg-filter"></div>
      <div class="text-wrapper">
        <div>公开透明</div>
        <div>OPENNESS AND TRANSPARENCY</div>
      </div>
      <SubNav :navList="navList" />
    </div>
    <keep-alive :max="10">
      <router-view :key="key"></router-view>
    </keep-alive>

    <ToTop />
  </div>
</template>

<script>
import SubNav from "@/components/SubNav";

import ToTop from "@/components/ToTop.vue";

export default {
  name: "Announcements",
  components: {
    SubNav,
    ToTop
  },
  data() {
    return {
      navList: [
        {
          name: "年度报告",
          path: "/announcements/annual-report",
          component: "AnnualReport"
        },
        {
          name: "审计报告",
          path: "/announcements/audit-report",
          component: "AuditReport"
        },
        {
          name: "项目报告",
          path: "/announcements/project-report",
          component: "ProjectReport"
        },
        {
          name: "资质规章",
          path: "/announcements/regulations",
          component: "Regulations"
        }
      ]
    };
  },
  computed: {
    key() {
      return this.$route.path;
    }
  }
};
</script>

<style lang="scss" scoped>
#announcement-wrapper {
  color: #1d1d1f;
}
.banner {
  background-image: url(../../assets/images/two-banner.jpeg);
  background-position: 50% 35%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  color: #fff;
  text-align: center;
  width: 80vw;
  min-width: 1240px;
  max-width: 100%;
  height: 23vw;
  min-height: 340px;
  max-height: 430px;
  margin: 0 auto;
  background-size: cover;
  align-items: center;
  position: relative;
  margin-bottom: 100px;
  // scroll-behavior: smooth;
  // 滤镜
  .bg-filter {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(37, 163, 246, 0.267);
  }
  // 文字
  .text-wrapper {
    z-index: 10;
    div:first-child {
      font-size: 40px;
      margin-bottom: 30px;
    }
    div:last-child {
      font-size: 20px;
    }
  }
}
</style>
